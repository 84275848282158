@import '../../theme.scss';

.Feats {

  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    .feat {
      text-align: left;

      .description {
        font-size: .6em;
      }
    }
  }
}