@import '../../theme.scss';

.Skills {

  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    .skill {
      text-align: left;
    }
  }
}