@import '../../theme.scss';

.Footer {
  padding-top: 3em;
  font-size: 1.1em;
  font-family: "Amatic SC";
  text-align:center;

  .copyright {
    color: $dark-text;;

    .name {
      color: $highlight-color;
    }
  }
}