@import "./../../theme.scss";

.App {
  height: 100%;
  scroll-snap-type: y mandatory;

  .Section {
    scroll-snap-align: start;
  }

  h3 {
    font-family: Arvo;
    display: block;
    text-align: center;
    font-size: 2em;
    margin-bottom: 1em;
    margin-top: 0;
    color: $dark-lines;
  }

  .section-icon {
    width: 100px;
    text-align: center;
    margin: 0 auto;
    display: block;

    &.flip {
      transform: scaleX(-1)
    }
  }
}
