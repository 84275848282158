@import '../../theme.scss';

.History {
  .position {
    font-size: .8em;
    font-weight: bolder;
  }

  .employer {
    font-size: .5em;
  }

  .duration {
    font-size: .5em;
  }

  p {
    font-family: "Amatic SC";
    margin-top: 0;
    font-size: 1.2em;
    font-weight: bold;

  }

  .rewards {
    font-size: .70em;
  }
}