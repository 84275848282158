@import '../../theme.scss';

.Intro {

  img {
    &.portrait {
      width: 100%;
      margin: 0 auto;
      display: block;
      border-radius: 100%;
      border: 3px solid transparent;
      box-shadow: $dark-lines 0 0 0 1px;
    }
  }

  .name {
    font-size:2em;
  }
}