@import '../../theme.scss';

.Skills {

  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    .skill .value {
      font-weight: bold;
      font-size: 1.2em;
      text-align: center;
      color: $dark-lines;

      .name {
        font-weight: bolder;
        text-align: right;
        font-size: 1.5em;
        color: $dark-text;
      }

      .total {
        font-size: 1.5em;
        text-align: left;
        color: $dark-text;
      }
    }
  }
}