@import '../../theme.scss';

.Field {

  .value {
    font-family: "Amatic SC";
    font-size: 30px;
    font-weight: bolder;
  }

  .label {
    color: $dark-lines;
    font-family: Arvo;
    font-size: 12px;
  }

}